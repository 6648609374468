import React from "react"
import styled from 'styled-components';
import Layout from "../components/layout";
import Classical from "../components/classical";
import { graphql } from "gatsby";
import CreatorWords from "../components/creator-words";
import TitleEffect from "../components/title-effect";
import Separator from "../components/separator";

import landscape from "../assets/home/landscape.jpg"
import portrait from "../assets/home/portrait.jpg"
import Banner from "../components/banner";
import Meta from "../components/meta";
import NosAccord from "../components/nos-accord";
import { extractPrice } from "../utils/price";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Classicals = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  gap: 64px;
  
  // & > * {
    // margin-left: 16px;
    // margin-right: 16px;
  // }
`;

const IndexPage = ({ data }) => {
  const classicals = data.allShopifyProduct.edges.map(({ node }) => node);
  const classical1 = classicals[0];
  const classical2 = classicals[1];
  const classical3 = classicals[2];

  const { price: price1, compareAtPrice: compareAtPrice1 } = extractPrice(classical1);
  const { price: price2, compareAtPrice: compareAtPrice2 } = extractPrice(classical2);
  const { price: price3, compareAtPrice: compareAtPrice3 } = extractPrice(classical3);

  return (
    <Layout>
      <Meta />
      <Container>
        <Banner portrait={portrait} landscape={landscape} padding={128} />

        <Separator size={64} />
        <TitleEffect>Nos classiques</TitleEffect>
        <Classicals>
          <Classical tags={classical1.tags} margin={"64px"} price={price1} compareAtPrice={compareAtPrice1} name={classical1.title} src={classical1.images[0].originalSrc} to={`/products/${classical1.handle}`} />
          <Classical tags={classical2.tags} name={classical2.title} price={price2} compareAtPrice={compareAtPrice2} src={classical2.images[0].originalSrc} to={`/products/${classical2.handle}`} />
          <Classical tags={classical3.tags} margin={"64px"} price={price3} compareAtPrice={compareAtPrice3} name={classical3.title} src={classical3.images[0].originalSrc} to={`/products/${classical3.handle}`} />
        </Classicals>

        <Separator size={64} />
        <NosAccord />

        <CreatorWords />

      </Container>
    </Layout>
  );
}

export default IndexPage

export const query = graphql`
  {
    allShopifyProduct(
      filter: {tags: {in: "home_page"}, availableForSale: {}}
      sort: {fields: [publishedAt]}
      limit: 3
    ) {
      edges {
        node {
          shopifyId
          title
          images {
            originalSrc
          }
          description
          handle
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          tags
          variants {
            id
            title
            price
            presentmentPrices {
              edges {
                node {
                  compareAtPrice {
                    amount
                  }
                  price {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`