import React from 'react';
import styled from 'styled-components';
import macaron from '../assets/macaron.png';
import italy from '../assets/italy.png';

const Img = styled.img`
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: ${props => props.width}px;
  padding: 4px;
  height: auto;
  z-index: 500;

  background-color: #f1ece9;
  border-radius: 50%;

  @media(max-width: 768px) {
    width: 44px;
    height: 44px;
    bottom: 8px;
    right: 8px;
  }
`;

const Macaron = ({ width, tags }) => {
  const isMadeInItaly = tags && tags.includes("made_in_italy");
  const isMadeInFrance = tags && tags.includes("made_in_france");
  return isMadeInFrance
    ? <Img src={macaron} width={width || 64} />
    : isMadeInItaly
      ? <Img src={italy} width={width || 64} />
      : <React.Fragment />
}

export default Macaron;