import React from 'react';
import styled from 'styled-components';
import profileImg from '../assets/profile.jpg';
import Separator from './separator';
import TitleEffect from './title-effect';
import { useStaticQuery, graphql } from "gatsby"

const TextContainer = styled.div`
  position: relative;
  border: 3px solid #F1EEE9;
  width: 1000px;
  max-width: 100vw;
  padding: 56px;
  padding-top: 16px;
  padding-bottom: 56px;
  box-sizing: border-box;
  text-align: justify;

  @media(max-width: 768px){
    padding-left: 16px; 
    padding-right: 16px;
    padding-bottom: 24px;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  border: 16px solid white;
  position: absolute;
  top: 0;
  width: 128px;
  height: 128px;
  transform: translate(-50%,-50%);
  left: 50%;

  @media(max-width: 768px){
    width: 96px;
    height: 96px;
  }
`;

const HtmlText = styled.div`
  font-size: 1.2rem;
  & > p {
    margin-top: 20px;
  }
`;


const CreatorWords = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/(creatrice)/"}) {
        id
        html
      }
    }
  `);

  return (
    <React.Fragment>
      <Separator size={128} />
      <TitleEffect>L'aparté de la fondatrice</TitleEffect>
      <Separator size={78} />
      <TextContainer>
        <Avatar src={profileImg} />
        <Separator size={76} />
        <HtmlText dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></HtmlText>
      </TextContainer>
    </React.Fragment >);
};

export default CreatorWords;