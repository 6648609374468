import React from 'react';
import { Helmet } from 'react-helmet';
import icon from "../assets/icons/icon.png";
import favicon from "../assets/icons/icon.ico";

const Meta = ({title}) => {
  return (
    <Helmet>
      <title>{`Arpèje${title ? ' - ' + title : ''}`}</title>
      <meta name="google-site-verification" content="Ho7my6JxhVhyzeiFgODpfmqBwUzf6gZg7H40VgVsVP8" />
      <meta name="description" content={"Arpèje est une boutique de prêt-à-porter féminin axée sur le made in France. Prenons le temps de revenir à un slowfashion satisfaisant."} />
      <link rel="icon" type="image/png" href={icon} sizes="16x16" />
      <link rel="icon" href={favicon} sizes="16x16" />
    </Helmet>
  );
}

export default Meta;