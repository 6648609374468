import React from 'react';
import styled from 'styled-components';

const Portrait = styled.img`
  width: 100%;
  height: auto;

  @media(orientation: landscape){
    display: none;
  }
`;

const Landscape = styled.img`
  width: 100%;
  height: auto;
  
  @media(orientation: portrait){
    display: none;
  }
`;

const Banner = ({portrait, landscape}) => {
  return (
    <React.Fragment>
      <Portrait src={portrait}/>
      <Landscape src={landscape}/>
    </React.Fragment>
  );
}

export default Banner;