import React from 'react';
import styled from 'styled-components';
import Macaron from './macaron';
import Separator from './separator';
import TitleEffect from './title-effect';
import {navigate} from "gatsby";

import big from "../assets/accord/big.jpg";
import small1 from "../assets/accord/small1.jpg";
import small2 from "../assets/accord/small2.jpg";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;
  width: 925px;

  @media(max-width: 768px){
    width: 100%;
    grid-gap: 4px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Product = styled.div`
  width: 100%;
  position: relative;

  cursor: ${props => props.onClick ? 'pointer' : 'inherit'};

  &:nth-child(1) {
    grid-column: span 4;
    grid-row: span 2;
  }

  &:nth-child(2) {
    grid-column: span 2;
  }

  &:nth-child(3) {
    grid-column: span 2;
  }
`;

const ACCORDS = [
  {
    src: big,
    tags: ["made_in_italy"],
    link: "/products/gilet-jasmine"
  },
  {
    src: small1,
    tags: [],
    link: "/products/gilet-jasmine",
  },
  {
    src: small2,
    tags:[],
    link:"/products/gilet-jasmine"
  },
]

const NosAccord = () => {
  return (
    <React.Fragment>
        <TitleEffect>Nos Accords</TitleEffect>
        <Separator size={48}/>
        <Container>
          {ACCORDS.map((accord, i) => {
            const isSmall = i > 0;
            const onClick = accord.link
              ? () =>  navigate(accord.link)
              : undefined;
            return (
                <Product key={i} onClick={onClick}>
                  <Image src={accord.src}/>
                  <Macaron width={isSmall ? 64 : 128} tags={accord.tags}/>
                </Product>
            )
          })}
        </Container>
    </React.Fragment>)
}

export default NosAccord;
