import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby'
import Macaron from './macaron';

const Container = styled(Link)`
  display: block; 
  background-color: #F1EEE9;
  max-width: 500px;
  height: 760px;
  padding: 16px 16px 32px 16px;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
  
  @media(max-width: 768px){
    height: 600px;
  }

  ${props => props.margin ?
    `
    margin-top: ${props.margin};
    @media(max-width: 1200px){
      margin-top: 16px;
    }
    `: `
    @media(max-width: 1200px){
      margin-top: 16px;
    }
    `
  }
`;

const ClassicalImage = styled.img`
  width:100%;
`;

const ImageContainer = styled.div`
  width:100%;
  position: relative;
`;

const ClassicalTitle = styled.div`
  text-align: center;
  font-size: 1.2rem;
  padding-top: 8px;
`;

const ClassicalPrice = styled.div`
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 8px;
  margin-top: 4px;
  color: #5F5F5F;
  display: flex;
  justify-content: center;
`;

const Price = styled.div``;

const CompareAtPrice = styled.div`
  position: relative;
  margin-left: 16px;
  :before {
    content: '';
    border-bottom: 2px solid #5F5F5F;
    width: 120%;
    position: absolute;
    left: -10%;
    top: 50%;
  }
`;


const Classical = ({ margin, name, src, to, className, tags, price, compareAtPrice }) => {
  return (
    <Container to={to} className={className} margin={margin}>
      <ImageContainer>
        <ClassicalImage src={src} />
        <Macaron tags={tags} />
      </ImageContainer>

      <ClassicalTitle>{name}</ClassicalTitle>
      <ClassicalPrice>
        <Price>{price}€</Price>
        {price !== compareAtPrice ? <CompareAtPrice>{compareAtPrice}€</CompareAtPrice> : null}
      </ClassicalPrice>
    </Container>
  );
}

export default Classical;